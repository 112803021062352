.main-chart-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: space-around;
}

.tile-button-container {
  display: inline-flex;
  margin: 1rem auto;
  flex-direction: column;
  align-content: flex-start;
  flex: unset;
}

.options-container {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /*width: calc(100% - 4em);*/
  height: 120px;
  padding: 0px 1em;
  white-space: nowrap;
}

.dateRangePicker {
  display: inline-flex;
  flex-direction: row;
  flex-grow: 1;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {

}

@media only screen and (min-width: 480px) and (max-width: 767px) {

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dateRangePicker {
    flex-direction: column;
  }

  .tile-button-container {
    flex: 1 0 100%;
  }
}

@media only screen and (min-width: 992px){
  .tile-button-container {
    flex: 1 0 50%;
  }
}

.aggregationSelectionPicker {
  display: inline-block;
  width: 15em;
}

.pivot-tbl-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
  height: auto;
}

.pivot-tbl {
  display: contents;
  position: absolute;
  flex-direction: row;
  justify-content: center;
}

.pvtUi {
  width: auto;
  position: relative;
  top: 0px;
  left: 0px;
}

.pvtVertList {
  width: 15%;
}

.DateRangePicker_picker {
  z-index: 2 !important;
}

@media print{@page {size: landscape}}

.metric-tile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.metric-tile-list {
  display: inline-flex;
  flex-direction: row;
  margin: 2rem;
  justify-content: center;
}

/* Chart styles */

.chartOne path {
  stroke: rgb(231, 76, 60) !important;
}

.chartTwo path {
  stroke: rgb(52, 152, 219) !important;
}

.chartThree path {
  stroke: rgb(26, 188, 156) !important;
}

.chartFour path {
  stroke: rgb(241, 196, 15) !important;
}

.chartFive path {
  stroke: rgb(230, 126, 34) !important;
}

.chartSix path {
  stroke: rgb(155, 89, 182) !important;
}

.chartSeven path {
  stroke: rgb(192, 57, 43) !important;
}

.chartEight path {
  stroke: rgb(88, 177, 159) !important;
}

.chartNine path {
  stroke: rgb(234, 181, 67) !important;
}

.chartTen path {
  stroke: rgb(125, 95, 255) !important;
}
